.asistencia-historial-grupo-familiar-modal {
    padding-bottom: 24px;
    .historial-grupo-familiar__grid__modal {
        padding: 24px 24px 94px ;
        --inner-padding-end: 0px;
    }
    .checkbox__integrantes-grupo-familiar {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .filtro-select-button {
        width: 80%;
    }

    .filtro-select-button-mobile {
        width: 80%;
        margin-bottom: 16px;
    }

    .separador_finalizadas {
        margin-top: 0px;
    }
    .filtro-finalizadas__container{

        .boton-pregunta-container{
            padding: 0px !important;
        }
    
    }
}