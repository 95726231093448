body {
    padding: 0 !important;
}

.asistencia-medica {
    flex-direction: column;
    align-items: center;

    .button-link {
        margin-bottom: var(--size-8);
        letter-spacing: normal;

        span {
            font-size: var(--size-14);
            line-height: var(--size-16);
            font-weight: 700;
        }
    }

    .separador-asistencia {
        margin: 35px 0 16px 0;
    }

    .terminos-condiciones-asistencia {
        padding-bottom: var(--size-24);
    }
}

.mt-40 {
    margin-top: 40px !important;
}

.mb-40 {
    margin-bottom: 40px !important;
}

.h-100 {
    height: 100vh !important;
}

.am-flex-sb{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

@media screen and (min-width: 750px) {
    .modal_web>.ion-overlay-wrapper {
        height: 75vh;
        border-radius: 24px !important;
        align-self: center !important;
    }
    .modal_web>.modal-wrapper {
        border-radius: 24px !important;
        align-self: center !important;
    }
    .asis-home-container {
        min-height: 80%;
    }

    .modal_web-large>.ion-overlay-wrapper {
        height: 85vh;
        border-radius: 24px !important;
        align-self: center !important;
    }
    
    .modal_web_xl>.ion-overlay-wrapper {
        border-radius: 24px !important;
        align-self: center !important;
        margin-bottom: var(--size-48);
    }
}

ion-header {
    ion-row {
        ion-col {
            h3 {
                padding-top: var(--size-13);
            }
        }
    }
}

input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}




// .asistencia-medica {
//     .asistencia__content {
//         ion-item {
//             --inner-padding-end: 0px;
//         }
//     }
//     .item {
//         --inner-padding-end: 0px;

//         &::part(native){
//             padding-inline-start: 0px;
//             padding-inline-end: 0px;
//         }

//         .item__label {
//             font-family: var(--font-family-secondary);
//             font-style: normal;
//             font-weight: 700;
//             font-size: var(--size-18);
//             line-height: 26px;
//             color: #202327;
//         }

//         .item__label--asistencia {
//             margin-bottom: var(--size-10);
//             font-family: var(--font-family-secundary);
//             font-style: normal;
//             font-weight: 600;
//             font-size: var(--size-12);
//             line-height: var(--size-16);
//             white-space: initial;
//             color: #555555;
//         }

//         .item__label--terminos {
//             font-style: normal;
//             font-weight: 700;
//             font-size: var(--size-12);
//             line-height: var(--size-16);
//             text-decoration: underline;
//         }

//         .item__label--editar {
//             font-style: normal;
//             font-weight: 700;
//             font-size: var(--size-12);
//             line-height: var(--size-16);
//             text-decoration: underline;
//         }
//     }
//     .footer-terminos-condiciones{
//         padding-bottom: var(--size-24);
//     }

//     .button-link {
//         margin: 5px 0 0 0;
//         .button__link--label {
//             font-family: var(--font-family-secundary);
//             font-style: normal;
//             font-weight: 700;
//             letter-spacing: normal;
//             font-size: var(--size-12);
//             line-height: var(--size-16);
//             color: var(--primary-blue);
//         }
//     }
// }