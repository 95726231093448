.asistencia-cambiar-socio {
    .cambiar-socio__button--padding {
        padding: 0 24px 24px 24px;
    }

    .cambiar-socio__button {
        margin-bottom: 24px;
    }

    .cambiar-socio__button__left {
        padding-right: 3.5px;
    }

    .cambiar-socio__button__right {
        padding-left: 3.5px;
    }

    .cambiar-socio__grid {
        padding: 54px 24px 24px;
    }
}