.asistencia-resumen { 

    .solicitar-asistencia-card{
        margin-bottom: 0px;
    }
    .contacto__row--margin-top {
        margin-top: 40px;
    }

    .contacto__row__button {
        margin-top: 56px;
        justify-content: center;
    }

    .contenido-inputs{
        padding-top: 16px;
    }
    
    .sticky-button-container {
        position: sticky;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 0 15px;
        bottom: 0;
    }
}