.footer-asistencia {
    width: 100%;

    .footer-row {
        display: flex;
        align-items: center;
        color: white;
    }

    .tool-bar-footer {
        background-color: var(--primary-blue);
        color: var(--color);
        font-family: "MuseoSans500", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 21px;

        .title-footer {
            padding: 0 4%;
            align-self: center;
            text-align: center;
        }

        .content-footer {
            margin: 10px 0;
            padding: 0;
            padding-left: 5%;
            border-left: 1px solid white;
            font-size: 13px;
        }

        .block-footer {
            display: block;
            max-width: 80%;
        }

        .external-link-footer {
            color: white;
            text-decoration: none;
            cursor: pointer;
            padding: 2px;
        }

        .external-link-footer:hover {
            color: #12caff;
        }

        .copyright-footer {

            .text-footer {
                font-size: 9px;
                padding: 7px 5px;
            }

            .icon-col-footer {
                margin: 5px;
            }

            .icon-footer {
                opacity: 0.4;
                height: 25px;
            }

            .icon-footer:hover {
                opacity: 1;
            }

        }
    }

}

.no-mobile {
    @media screen and (max-width: 750px) {
        display: none !important;
    }
}

.no-desktop {
    @media screen and (min-width: 750px) {
        display: none !important;
    }
}