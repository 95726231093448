.proximidad-ubicacion {
	position: fixed;
	background: var(--white);
	z-index: 99999;
	border-radius: 24px 24px 0 0;
    width: 100%;

    .proximidad-ubicacion-grid {
        padding: 54px 24px  100px 24px;
    }
    .proximidad-ubicacion-footer {
        padding: 0 17px 0 24px;
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        background: white;
        .row-btns {
            ion-col{
                .ubicacion_button{   
                    padding-right: 7px !important;                
                }
            }
        }
    }
}

.proxidad-ubicacion-mobile {
    .proximidad-ubicacion-footer {
        padding: 0 17px 0 24px;
        margin-bottom: 16px;
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        background: white;
        .row-btns {
            ion-col{
                .ubicacion_button{   
                    padding-right: 7px !important;                
                }
            }
        }
    }

    .proximidad-ubicacion-grid {
        .card-nota--container {
            width: 100%;
        }
    }
}