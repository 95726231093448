.asistencia-antecedentes {
    ion-grid.content__grid {
        padding: 24px 24px;
    }
    .antecedentes__title {
        padding: 0px 0px 8px 0px;
        margin: 0px;
    }

    .footer_antecedentes {
        padding: 20px 0px 26px;
        background: var(--white-transparent);
        
        .footer_button_antecedentes {
            margin: 0px;
        }
    }
}