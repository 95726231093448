@import "../../../../themes/variables.scss";

.asistencia-formulario {
    &__header {
        padding: 54px 24px 24px;
		position: fixed;
		background: var(--white);
		z-index: 99999;
		border-radius: 24px 24px 0 0;
        width: 100%;

		h2 {
			padding: 0;
		}
    }

    .col__align {
        align-self: center;
    }
    
    .col--padding-top {
        padding-top: 16px;
    }

    .col--padding-right {
        padding-right: 3.5px;
    }

    .col--padding-left {
        padding-left: 3.5px;
    }

    .button--margin {
        margin-top: 24px;
    }

    .guardar-cambios {
        margin-bottom: 16px;
        padding-bottom: 16px;
    }
}

.asistencia-formulario-mobile {
    .guardar-cambios {
        margin-bottom: 16px;
    }
}