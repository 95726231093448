@import "../../../../themes/variables.scss";
@import "../../../../themes/_mixins.scss";

.asistencia-eliminar-domicilio {

    .feedback--container{
        height: auto;
    }
    .feedback.boton-no-flotante .botones__area{
        padding-bottom: 0;
    }
    .feedback--white.boton-no-flotante .feedback-row{
        height: calc(100vh - 146px);

        @include respond(mobileSM) {
			height: calc(100vh - 201px)
		}
    }
    .feedback--imagen{
        top: -50px;
    }
    .feedback__text {
        font-family: var(--font-family-secondary);
        font-style: normal;
        font-size: var(--size-16);
        line-height: 22px;

        .label--font-weight {
            font-weight: bold;
        }
    }
    .feedback .botones__area {
        margin-bottom: 16px;
    }
}

.asistencia-eliminar-domicilio-mobile {
    .feedback .botones__area {
        margin-bottom: 16px;
    }
}