.row-osde {
    height: 70px;
    background-color: #F9F9F9;

    img.logo-osde {
        height: 40px;
        top: 0px;
        position: absolute;
        left: 10vw;
        top: 2vh;
    }

}

.logoutX {
    position: absolute;
    right: 30px;
    bottom: 10px;
    color: #FFFFFF;
    cursor: pointer;
    width: 100%;
    text-decoration: underline;
    text-underline-offset: 2px;
}

.row-session-bar {

    .user-name {
        padding-right: 5px;
    }

    height: 26px;
    padding-right: 5%;
    background-color: #7F7F7F;
    font-family: 'Nunito';
    font-weight: 700;
    font-size: 11px;
    color: white;

    .logout-button {
        cursor: pointer;
        padding: 1px 5px;
        border-left: 1px solid white;
        border-right: 1px solid white;
    }

    .logout-button:hover {
        text-decoration: underline;
    }

}

.asistencia-tool-bar {
    --background: var(--primary-blue);
    --color: var(--color);
    display: flex;

    .asistencia-title {
        font-size: var(--size-24) !important;
        line-height: var(--size-34) !important;
        font-family: var(--font-family-primary);
        font-weight: 700 !important;
        margin-top: 55px;
        margin-bottom: 31px;
        text-align: center;
        color: white;

        @media screen and (max-width: 400px) {
            font-size: var(--size-18) !important;
        }
    }
}