.asistencia-seleccionar-socio {
    flex-direction: column;
    align-items: center;

    .seleccionar-socio__title {
        padding: 0px 0px 8px 0px;
        margin: 0px;
    }

    .seleccionar-socio__subtitle {
        font-style: normal;
        font-weight: 600 !important;
        font-size: var(--size-16);
        line-height: var(--size-22);
        padding-bottom: var(--size-24);
    }

    .seleccionar-socio__button {
        margin-bottom: var(--size-8);

        span {
            letter-spacing: normal;
            font-size: var(--size-14);
        }
    }
}