.asistencia-medica-historial-casos-finalizados {
    .swiper-wrapper {
        // height: 114px!important;

        .swiper-slide:first-of-type {
            margin-left: 0px!important;
        }
    }
}
.asistencia-medica{
    .turnos--slider .card-turnos--area .data--area{
        height: auto;
    }
}