.asistencia-resumen-ysocial {
    .solicitar-asistencia-ysocial-card{
        margin-bottom: 0px;
    }
    .contacto__ysocial_row--margin-top {
        margin-top: 40px;
    }
    .contenido-ysocial-inputs{
        padding-top: 16px;
    }
    .continuar-ysocial__button {
        margin-top: 20px;
        margin-bottom: 45px;
    }

    @media screen and (max-width: 750px) {
		width: 100%;
        .sticky-button-container {
            position: sticky;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            padding: 0;
            bottom: 0;
        }
	}
}