.asistencia-editar-datos{
    overflow: hidden;
    .input-telefono{
        padding-bottom: 12px;
    }

    .boton-guardar-cambios{
        margin-bottom: 25px;
        width: 80%;
    }
}