.pregunta__container{

    .subtitulo-pregunta-container{
        padding-top: 16px;
    }

    .boton-pregunta-container{
        padding: 0px !important;
    }



}
