.btn-solicitar-otra-asistencia{
    margin-top: var(--size-8);
    span{
        font-size: var(--size-12);
        font-weight: 700;
        letter-spacing: normal;
    }
}

.asistencia-medica-card-solicitar-asistencia {
    padding-bottom: 16px;
}