.asistencia-verificar-datos-contacto {

    .titulo-confirma-datos {
        padding-bottom: var(--size-8);
    }

    .verificar-datos-contactos_button {
        margin-bottom: 45px;
      }

}

.verificar-datos-grid {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 85%;

    @media screen and (min-width: 750px) {
        height: 80%;
    }
}