@import "../../../../src/themes/variables.scss";

.asistencia-medica-direcciones {
    
    .button--ripple {
        --ripple-color: transparent;
        margin-bottom: 0px;
    }

    .georeferencia__col {
        padding-right: 24px;
        padding-left: 24px;
        padding-top: 24px;
    }

    .direcciones__col--padding {
        padding-right: 24px;
        margin-bottom: 25vh;
    }

    .sin-domicilio {
        padding: 24px 24px 16px 24px;
    }
}

.direcciones_padding {
    padding: 0 24px 24px;
}

.direcciones_container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}