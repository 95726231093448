.asistencia-antecedentes-skeleton {
    width: 100%;
    .title__col {
        padding-top: 8px;
        padding-bottom: 20px;
    }
    
    .radio-item {
        margin-top: 8px;
    }
}