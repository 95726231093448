.asistencia-sintomas-skeleton {
    .title__col {
        padding-top: 8px;
    }

    .title--margin-bottom {
        margin-bottom: 5px;
    }
    
    .radio-item {
        margin-top: 8px;
    }
}