.datos-ubicacion {
    box-sizing: border-box;
    width: 100vw;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;

    background: #FFFFFF;
    border: 2px solid #E7E9F7;
    border-radius: 16px;
    margin-top: 8px;

    .subtitulo--padding {
        padding: 0px;
    }
}

.col__radio-button {
    align-items: center;
    display:flex;
    justify-content: center;
}

.option {
    background: #DF3045;
    border-radius: 16px;

    .icon {
        font-size: var(--size-24);
    }

    .label--underline {
        text-decoration: underline;
        text-transform: capitalize;
    }
}