.datos-ysocial { 

    .contacto__row--margin-top {
        margin-top: 40px;
    }

    .contenido-inputs{
        padding-top: 16px;
    }

    .continuar__ysocial__button {
        margin-top: 20px;
        margin-bottom: 45px;
    }

    .row-no-datos {
        margin-bottom: 10px;
    }

    .domicilios_card {
        border: 2px solid #F1F2FA;
        width: 100%;
        border-radius: 16px;
        padding: 10px 24px;
        overflow: hidden;
        max-height: 40%;
    }
}