.loading-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #1226AA;
}

.loading-animation {
    width: 30rem;
    height: 30rem;
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.8);
    }

    70% {
        transform: scale(1);
    }

    100% {
        transform: scale(0.8);
    }
}