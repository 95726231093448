.asistencia-medica-detalle-caso {
    .grid {
        padding: 24px 24px 15vh 24px;
	}

	.datos-atencion__row {
		margin-top: var(--size-40);
	}

	.datos-ubicacion__row {
		margin-bottom: var(--size-40);
	}
}