.detalle__caso__skeleton {

    .dc__skeleton__container {
        padding: 64px 24px 0 24px;
    }

    .detallecaso__skeleton__row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 20px;
    }

    .dc__center {
        display: flex;
        justify-content: center;
    }

    .dc__right {
        display: flex;
        justify-content: flex-end;
    }

    .title__col {
        padding-top: 8px;
        padding-bottom: 20px;
    }

    .radio-item {
        margin-top: 8px;
    }
}