.asistencia__normalizacion {
    &__header {
        padding: 56px 24px 0 24px;
    }
    
    &__footer {
        padding: 0 17px 0 24px;
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        background: white;
        .norm_button_container{
            ion-col{
                .norm_button{   
                    padding-right: 7px !important;                
                }
            }
        }
    }

    .radio_container {
        padding: 0 15px ;
    }
    
}

.asistencia__normalizacion-mobile {
    .asistencia__normalizacion__footer {
        margin-bottom: var(--size-16);
    }
}