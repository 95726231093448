.asistencia-sintomas {
    .footer__sintomas {
        background-color: var(--white);
        padding: 23px 0px 0px;

        .footer__button {
            margin-bottom: 45px;
            padding: 0;
        }

    }
}