@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;700;800;900&family=Nunito:wght@300;400;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;700;800;900&family=Nunito:wght@300;400;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;700;800;900&family=Nunito:wght@300;400;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;700;800;900&family=Nunito:wght@300;400;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;700;800;900&family=Nunito:wght@300;400;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;700;800;900&family=Nunito:wght@300;400;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;700;800;900&family=Nunito:wght@300;400;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;700;800;900&family=Nunito:wght@300;400;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;700;800;900&family=Nunito:wght@300;400;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;700;800;900&family=Nunito:wght@300;400;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;700;800;900&family=Nunito:wght@300;400;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;700;800;900&family=Nunito:wght@300;400;700;800;900&display=swap);
ion-grid.content__grid{padding:24px}ion-col.grid__col{padding-top:24px}.asistencia-medica ion-header.modal__header{padding:54px 24px 24px;position:fixed;background:var(--white);z-index:99999;border-radius:24px 24px 0 0}.asistencia-medica ion-header.modal__header h2{padding:0}ion-grid.modal__grid{padding:115px 24px 0 24px;--inner-padding-end: 0px}.ysocial-background-page{background:linear-gradient(180deg, rgb(18, 38, 170) 50%, rgb(56, 76, 207) 100%);width:100%}.ysocial-padding{padding-bottom:0px}@media screen and (max-width: 750px){.ysocial-padding{padding-bottom:50px}}.ysocial_content-container{width:35rem;min-height:80vh;margin:auto}@media screen and (max-width: 750px){.ysocial_content-container{width:100%}}.asistencia-contactanos .modal__grid-contactanos{margin-top:2em}.asistencia-contactanos .contactanos-row{margin-bottom:16px}
ion-grid.content__grid{padding:24px}ion-col.grid__col{padding-top:24px}.asistencia-medica ion-header.modal__header{padding:54px 24px 24px;position:fixed;background:var(--white);z-index:99999;border-radius:24px 24px 0 0}.asistencia-medica ion-header.modal__header h2{padding:0}ion-grid.modal__grid{padding:115px 24px 0 24px;--inner-padding-end: 0px}.ysocial-background-page{background:linear-gradient(180deg, rgb(18, 38, 170) 50%, rgb(56, 76, 207) 100%);width:100%}.ysocial-padding{padding-bottom:0px}@media screen and (max-width: 750px){.ysocial-padding{padding-bottom:50px}}.ysocial_content-container{width:35rem;min-height:80vh;margin:auto}@media screen and (max-width: 750px){.ysocial_content-container{width:100%}}.asistencia-error_page{background:linear-gradient(180deg, rgb(18, 38, 170) 50%, rgb(56, 76, 207) 100%);width:100%}.asistencia-web-mobile-padding{padding-bottom:0px}@media screen and (max-width: 750px){.asistencia-web-mobile-padding{padding-bottom:50px}}
.asistencia-antecedentes ion-grid.content__grid{padding:24px 24px}.asistencia-antecedentes .antecedentes__title{padding:0px 0px 8px 0px;margin:0px}.asistencia-antecedentes .footer_antecedentes{padding:20px 0px 26px;background:var(--white-transparent)}.asistencia-antecedentes .footer_antecedentes .footer_button_antecedentes{margin:0px}
.asistencia-antecedentes-skeleton{width:100%}.asistencia-antecedentes-skeleton .title__col{padding-top:8px;padding-bottom:20px}.asistencia-antecedentes-skeleton .radio-item{margin-top:8px}
.row-osde{height:70px;background-color:#f9f9f9}.row-osde img.logo-osde{height:40px;top:0px;position:absolute;left:10vw;top:2vh}.logoutX{position:absolute;right:30px;bottom:10px;color:#fff;cursor:pointer;width:100%;text-decoration:underline;text-underline-offset:2px}.row-session-bar{height:26px;padding-right:5%;background-color:#7f7f7f;font-family:"Nunito";font-weight:700;font-size:11px;color:#fff}.row-session-bar .user-name{padding-right:5px}.row-session-bar .logout-button{cursor:pointer;padding:1px 5px;border-left:1px solid #fff;border-right:1px solid #fff}.row-session-bar .logout-button:hover{text-decoration:underline}.asistencia-tool-bar{--background: var(--primary-blue);--color: var(--color);display:flex}.asistencia-tool-bar .asistencia-title{font-size:var(--size-24) !important;line-height:var(--size-34) !important;font-family:var(--font-family-primary);font-weight:700 !important;margin-top:55px;margin-bottom:31px;text-align:center;color:#fff}@media screen and (max-width: 400px){.asistencia-tool-bar .asistencia-title{font-size:var(--size-18) !important}}
@media screen and (min-width: 750px){#modal-confirm-cierre-sesion>.ion-overlay-wrapper{border-radius:24px !important;align-self:center !important}}
.web_layout-container{display:flex;flex-direction:column;align-items:center;min-height:100vh}.asistencia_content-container{width:35rem;min-height:80vh;margin:auto}@media screen and (max-width: 750px){.asistencia_content-container{width:100%}}
.footer-asistencia{width:100%}.footer-asistencia .footer-row{display:flex;align-items:center;color:#fff}.footer-asistencia .tool-bar-footer{background-color:var(--primary-blue);color:var(--color);font-family:"MuseoSans500","Helvetica Neue",Helvetica,Arial,sans-serif;font-size:21px}.footer-asistencia .tool-bar-footer .title-footer{padding:0 4%;align-self:center;text-align:center}.footer-asistencia .tool-bar-footer .content-footer{margin:10px 0;padding:0;padding-left:5%;border-left:1px solid #fff;font-size:13px}.footer-asistencia .tool-bar-footer .block-footer{display:block;max-width:80%}.footer-asistencia .tool-bar-footer .external-link-footer{color:#fff;text-decoration:none;cursor:pointer;padding:2px}.footer-asistencia .tool-bar-footer .external-link-footer:hover{color:#12caff}.footer-asistencia .tool-bar-footer .copyright-footer .text-footer{font-size:9px;padding:7px 5px}.footer-asistencia .tool-bar-footer .copyright-footer .icon-col-footer{margin:5px}.footer-asistencia .tool-bar-footer .copyright-footer .icon-footer{opacity:.4;height:25px}.footer-asistencia .tool-bar-footer .copyright-footer .icon-footer:hover{opacity:1}@media screen and (max-width: 750px){.no-mobile{display:none !important}}@media screen and (min-width: 750px){.no-desktop{display:none !important}}
.btn-solicitar-otra-asistencia{margin-top:var(--size-8)}.btn-solicitar-otra-asistencia span{font-size:var(--size-12);font-weight:700;letter-spacing:normal}.asistencia-medica-card-solicitar-asistencia{padding-bottom:16px}
ion-grid.content__grid{padding:24px}ion-col.grid__col{padding-top:24px}.asistencia-medica ion-header.modal__header{padding:54px 24px 24px;position:fixed;background:var(--white);z-index:99999;border-radius:24px 24px 0 0}.asistencia-medica ion-header.modal__header h2{padding:0}ion-grid.modal__grid{padding:115px 24px 0 24px;--inner-padding-end: 0px}.ysocial-background-page{background:linear-gradient(180deg, rgb(18, 38, 170) 50%, rgb(56, 76, 207) 100%);width:100%}.ysocial-padding{padding-bottom:0px}@media screen and (max-width: 750px){.ysocial-padding{padding-bottom:50px}}.ysocial_content-container{width:35rem;min-height:80vh;margin:auto}@media screen and (max-width: 750px){.ysocial_content-container{width:100%}}.asistencia-terminos-condiciones .text__label{padding-bottom:24px;font-family:var(--font-family-secondary);font-style:normal;font-weight:600;font-size:var(--size-12);line-height:16px}.asistencia-terminos-condiciones .text__label .text__label--color{color:var(--primary-black)}.asistencia-terminos-condiciones .footer{background-color:rgba(255,255,255,.5);padding:23px 24px 59px 24px}.asistencia-terminos-condiciones .footer .footer_button{margin:0px}
.asistencia-medica-skeleton .col--display{display:flex;justify-content:center}.asistencia-medica-skeleton .title{padding-bottom:8px}.asistencia-medica-skeleton .button-link{margin-top:8px}.asistencia-medica-skeleton .item__skeleton--50{width:50vw}.asistencia-medica-skeleton .item__row{width:100vw}.asistencia-medica-skeleton .item__grid{margin-bottom:20px}.asistencia-medica-skeleton .solicitar-asistencia__card{display:flex;flex-direction:column;border-radius:16px;box-shadow:none;--background: #E7E9F7}.asistencia-medica-skeleton .solicitar-asistencia__card .card__title{margin-bottom:5px}.asistencia-medica-skeleton .solicitar-asistencia__card .card__button{margin:16px 0 0 0}.asistencia-medica-skeleton .footer-terminos-condiciones{margin-bottom:8px;padding:0 0 100px 0}
.asistencia-medica-historial-casos-finalizados .swiper-wrapper .swiper-slide:first-of-type{margin-left:0px !important}.asistencia-medica .turnos--slider .card-turnos--area .data--area{height:auto}
.asistencia-medica-deshabilitar-asistencia .deshabilitar__grid{padding:var(--size-24)}.asistencia-medica-deshabilitar-asistencia .subtitulo{padding-bottom:var(--size-20)}.asistencia-medica-deshabilitar-asistencia-mobile .row{margin-top:var(--size-16)}
body{padding:0 !important}.asistencia-medica{flex-direction:column;align-items:center}.asistencia-medica .button-link{margin-bottom:var(--size-8);letter-spacing:normal}.asistencia-medica .button-link span{font-size:var(--size-14);line-height:var(--size-16);font-weight:700}.asistencia-medica .separador-asistencia{margin:35px 0 16px 0}.asistencia-medica .terminos-condiciones-asistencia{padding-bottom:var(--size-24)}.mt-40{margin-top:40px !important}.mb-40{margin-bottom:40px !important}.h-100{height:100vh !important}.am-flex-sb{display:flex;flex-direction:column;align-items:center;justify-content:space-between}@media screen and (min-width: 750px){.modal_web>.ion-overlay-wrapper{height:75vh;border-radius:24px !important;align-self:center !important}.modal_web>.modal-wrapper{border-radius:24px !important;align-self:center !important}.asis-home-container{min-height:80%}.modal_web-large>.ion-overlay-wrapper{height:85vh;border-radius:24px !important;align-self:center !important}.modal_web_xl>.ion-overlay-wrapper{border-radius:24px !important;align-self:center !important;margin-bottom:var(--size-48)}}ion-header ion-row ion-col h3{padding-top:var(--size-13)}input::-webkit-inner-spin-button{-webkit-appearance:none;margin:0}
ion-grid.content__grid{padding:24px}ion-col.grid__col{padding-top:24px}.asistencia-medica ion-header.modal__header{padding:54px 24px 24px;position:fixed;background:var(--white);z-index:99999;border-radius:24px 24px 0 0}.asistencia-medica ion-header.modal__header h2{padding:0}ion-grid.modal__grid{padding:115px 24px 0 24px;--inner-padding-end: 0px}.ysocial-background-page{background:linear-gradient(180deg, rgb(18, 38, 170) 50%, rgb(56, 76, 207) 100%);width:100%}.ysocial-padding{padding-bottom:0px}@media screen and (max-width: 750px){.ysocial-padding{padding-bottom:50px}}.ysocial_content-container{width:35rem;min-height:80vh;margin:auto}@media screen and (max-width: 750px){.ysocial_content-container{width:100%}}.feedback .feedback--container .botones__area{padding-bottom:4rem}
.asistencia-editar-datos{overflow:hidden}.asistencia-editar-datos .input-telefono{padding-bottom:12px}.asistencia-editar-datos .boton-guardar-cambios{margin-bottom:25px;width:80%}
.asistencia-verificar-datos-contacto .titulo-confirma-datos{padding-bottom:var(--size-8)}.asistencia-verificar-datos-contacto .verificar-datos-contactos_button{margin-bottom:45px}.verificar-datos-grid{display:flex;flex-direction:column;justify-content:space-between;height:85%}@media screen and (min-width: 750px){.verificar-datos-grid{height:80%}}
.asistencia__pedidoServicio .footer__button{padding-right:24px;padding-left:24px}.asistencia__pedidoServicio .pedidoServicio__button_solicitar{margin:0px}.asistencia__pedidoServicio .pedido-servicio--row{padding:24px 24px 0}.asistencia__pedidoServicio .cancelar__button{padding-bottom:14px}.asistencia__pedidoServicio .pedidoServicio__title{padding:0px}.asistencia__pedidoServicio-mobile .content__grid{padding:0 0 var(--size-16) 0}.asistencia__pedidoServicio-mobile .pedidoServicio__title{padding:0px}
ion-grid.content__grid{padding:24px}ion-col.grid__col{padding-top:24px}.asistencia-medica ion-header.modal__header{padding:54px 24px 24px;position:fixed;background:var(--white);z-index:99999;border-radius:24px 24px 0 0}.asistencia-medica ion-header.modal__header h2{padding:0}ion-grid.modal__grid{padding:115px 24px 0 24px;--inner-padding-end: 0px}.ysocial-background-page{background:linear-gradient(180deg, rgb(18, 38, 170) 50%, rgb(56, 76, 207) 100%);width:100%}.ysocial-padding{padding-bottom:0px}@media screen and (max-width: 750px){.ysocial-padding{padding-bottom:50px}}.ysocial_content-container{width:35rem;min-height:80vh;margin:auto}@media screen and (max-width: 750px){.ysocial_content-container{width:100%}}.asistencia-formulario__header{padding:54px 24px 24px;position:fixed;background:var(--white);z-index:99999;border-radius:24px 24px 0 0;width:100%}.asistencia-formulario__header h2{padding:0}.asistencia-formulario .col__align{align-self:center}.asistencia-formulario .col--padding-top{padding-top:16px}.asistencia-formulario .col--padding-right{padding-right:3.5px}.asistencia-formulario .col--padding-left{padding-left:3.5px}.asistencia-formulario .button--margin{margin-top:24px}.asistencia-formulario .guardar-cambios{margin-bottom:16px;padding-bottom:16px}.asistencia-formulario-mobile .guardar-cambios{margin-bottom:16px}
.asistencia__normalizacion__header{padding:56px 24px 0 24px}.asistencia__normalizacion__footer{padding:0 17px 0 24px;position:fixed;left:0;bottom:0;width:100%;background:#fff}.asistencia__normalizacion__footer .norm_button_container ion-col .norm_button{padding-right:7px !important}.asistencia__normalizacion .radio_container{padding:0 15px}.asistencia__normalizacion-mobile .asistencia__normalizacion__footer{margin-bottom:var(--size-16)}
.asistencia-seleccionar-domicilio .sd__title{padding-bottom:5px}.asistencia-seleccionar-domicilio .domicilios_card{border:2px solid #f1f2fa;width:100%;border-radius:16px;padding:10px 24px;overflow:hidden;max-height:40%}.asistencia-seleccionar-domicilio .domicilio__info{display:flex;flex-direction:column}.asistencia-seleccionar-domicilio .domicilio__info .info__item{font-size:var(--size-5)}.asistencia-seleccionar-domicilio .domicilio__info .info__title{font-weight:bold}.asistencia-seleccionar-domicilio .domicilio__info .subtitulo--padding{padding:0px}.asistencia-seleccionar-domicilio .form__link{padding:15px 0;justify-content:flex-end}.asistencia-seleccionar-domicilio .seleccionar-domicilio__button{margin-bottom:45px}.asistencia-seleccionar-domicilio .seleccionar-domicilio-mobile__button{margin-bottom:45px}
.asistencia__normalizacion__header{padding:56px 24px 0 24px}.asistencia__normalizacion__footer{padding:0 17px 0 24px;position:fixed;left:0;bottom:0;width:100%;background:#fff}.asistencia__normalizacion__footer .norm_button_container ion-col .norm_button{padding-right:7px !important}.asistencia__normalizacion .radio_container{padding:0 15px}
.datos-ubicacion{box-sizing:border-box;width:100vw;display:flex;flex-direction:column;align-items:flex-start;padding:16px;background:#fff;border:2px solid #e7e9f7;border-radius:16px;margin-top:8px}.datos-ubicacion .subtitulo--padding{padding:0px}.col__radio-button{align-items:center;display:flex;justify-content:center}.option{background:#df3045;border-radius:16px}.option .icon{font-size:var(--size-24)}.option .label--underline{text-decoration:underline;text-transform:capitalize}
ion-grid.content__grid{padding:24px}ion-col.grid__col{padding-top:24px}.asistencia-medica ion-header.modal__header{padding:54px 24px 24px;position:fixed;background:var(--white);z-index:99999;border-radius:24px 24px 0 0}.asistencia-medica ion-header.modal__header h2{padding:0}ion-grid.modal__grid{padding:115px 24px 0 24px;--inner-padding-end: 0px}.ysocial-background-page{background:linear-gradient(180deg, rgb(18, 38, 170) 50%, rgb(56, 76, 207) 100%);width:100%}.ysocial-padding{padding-bottom:0px}@media screen and (max-width: 750px){.ysocial-padding{padding-bottom:50px}}.ysocial_content-container{width:35rem;min-height:80vh;margin:auto}@media screen and (max-width: 750px){.ysocial_content-container{width:100%}}.asistencia-eliminar-domicilio .feedback--container{height:auto}.asistencia-eliminar-domicilio .feedback.boton-no-flotante .botones__area{padding-bottom:0}.asistencia-eliminar-domicilio .feedback--white.boton-no-flotante .feedback-row{height:calc(100vh - 146px)}@media only screen and (max-width: 374px)and (max-height: 710px){.asistencia-eliminar-domicilio .feedback--white.boton-no-flotante .feedback-row{height:calc(100vh - 201px)}}.asistencia-eliminar-domicilio .feedback--imagen{top:-50px}.asistencia-eliminar-domicilio .feedback__text{font-family:var(--font-family-secondary);font-style:normal;font-size:var(--size-16);line-height:22px}.asistencia-eliminar-domicilio .feedback__text .label--font-weight{font-weight:bold}.asistencia-eliminar-domicilio .feedback .botones__area{margin-bottom:16px}.asistencia-eliminar-domicilio-mobile .feedback .botones__area{margin-bottom:16px}
ion-grid.content__grid{padding:24px}ion-col.grid__col{padding-top:24px}.asistencia-medica ion-header.modal__header{padding:54px 24px 24px;position:fixed;background:var(--white);z-index:99999;border-radius:24px 24px 0 0}.asistencia-medica ion-header.modal__header h2{padding:0}ion-grid.modal__grid{padding:115px 24px 0 24px;--inner-padding-end: 0px}.ysocial-background-page{background:linear-gradient(180deg, rgb(18, 38, 170) 50%, rgb(56, 76, 207) 100%);width:100%}.ysocial-padding{padding-bottom:0px}@media screen and (max-width: 750px){.ysocial-padding{padding-bottom:50px}}.ysocial_content-container{width:35rem;min-height:80vh;margin:auto}@media screen and (max-width: 750px){.ysocial_content-container{width:100%}}.asistencia-medica-direcciones .button--ripple{--ripple-color: transparent;margin-bottom:0px}.asistencia-medica-direcciones .georeferencia__col{padding-right:24px;padding-left:24px;padding-top:24px}.asistencia-medica-direcciones .direcciones__col--padding{padding-right:24px;margin-bottom:25vh}.asistencia-medica-direcciones .sin-domicilio{padding:24px 24px 16px 24px}.direcciones_padding{padding:0 24px 24px}.direcciones_container{display:flex;justify-content:space-between;flex-direction:column}
.proximidad-ubicacion{position:fixed;background:var(--white);z-index:99999;border-radius:24px 24px 0 0;width:100%}.proximidad-ubicacion .proximidad-ubicacion-grid{padding:54px 24px 100px 24px}.proximidad-ubicacion .proximidad-ubicacion-footer{padding:0 17px 0 24px;position:fixed;left:0;bottom:0;width:100%;background:#fff}.proximidad-ubicacion .proximidad-ubicacion-footer .row-btns ion-col .ubicacion_button{padding-right:7px !important}.proxidad-ubicacion-mobile .proximidad-ubicacion-footer{padding:0 17px 0 24px;margin-bottom:16px;position:fixed;left:0;bottom:0;width:100%;background:#fff}.proxidad-ubicacion-mobile .proximidad-ubicacion-footer .row-btns ion-col .ubicacion_button{padding-right:7px !important}.proxidad-ubicacion-mobile .proximidad-ubicacion-grid .card-nota--container{width:100%}
.asistencia-sintomas-skeleton .title__col{padding-top:8px}.asistencia-sintomas-skeleton .title--margin-bottom{margin-bottom:5px}.asistencia-sintomas-skeleton .radio-item{margin-top:8px}
.asistencia-sintomas .footer__sintomas{background-color:var(--white);padding:23px 0px 0px}.asistencia-sintomas .footer__sintomas .footer__button{margin-bottom:45px;padding:0}
.asistencia-preguntas .seleccionar-socio__title{padding-bottom:0px;margin-bottom:8px}.asistencia-preguntas .preguntas__row_button_continuar{padding-top:20px}.asistencia-preguntas .preguntas__button_preguntas{margin:0px}
.pregunta__container .subtitulo-pregunta-container{padding-top:16px}.pregunta__container .boton-pregunta-container{padding:0px !important}
.asistencia-observaciones-adicionales .footer__button{padding-right:24px;padding-left:24px}
.asistencia-cambiar-socio .cambiar-socio__button--padding{padding:0 24px 24px 24px}.asistencia-cambiar-socio .cambiar-socio__button{margin-bottom:24px}.asistencia-cambiar-socio .cambiar-socio__button__left{padding-right:3.5px}.asistencia-cambiar-socio .cambiar-socio__button__right{padding-left:3.5px}.asistencia-cambiar-socio .cambiar-socio__grid{padding:54px 24px 24px}
.asistencia-resumen .solicitar-asistencia-card{margin-bottom:0px}.asistencia-resumen .contacto__row--margin-top{margin-top:40px}.asistencia-resumen .contacto__row__button{margin-top:56px;justify-content:center}.asistencia-resumen .contenido-inputs{padding-top:16px}.asistencia-resumen .sticky-button-container{position:-webkit-sticky;position:sticky;display:flex;align-items:center;justify-content:center;width:100%;padding:0 15px;bottom:0}
.asistencia-seleccionar-socio{flex-direction:column;align-items:center}.asistencia-seleccionar-socio .seleccionar-socio__title{padding:0px 0px 8px 0px;margin:0px}.asistencia-seleccionar-socio .seleccionar-socio__subtitle{font-style:normal;font-weight:600 !important;font-size:var(--size-16);line-height:var(--size-22);padding-bottom:var(--size-24)}.asistencia-seleccionar-socio .seleccionar-socio__button{margin-bottom:var(--size-8)}.asistencia-seleccionar-socio .seleccionar-socio__button span{letter-spacing:normal;font-size:var(--size-14)}
.asistencia-medica-servicio-en-curso .titulo-servicio-en-curso{padding:0 0 var(--size-16)}.asistencia-medica-servicio-en-curso .subtitulo-servicio-en-curso{padding:0 0 var(--size-24)}.asistencia-medica-servicio-en-curso .boton-volver{padding-left:24px;padding-right:3.5px}.asistencia-medica-servicio-en-curso .boton-contactanos{padding-right:24px;padding-left:3.5px}
.asistencia-historial-grupo-familiar-modal{padding-bottom:24px}.asistencia-historial-grupo-familiar-modal .historial-grupo-familiar__grid__modal{padding:24px 24px 94px;--inner-padding-end: 0px}.asistencia-historial-grupo-familiar-modal .checkbox__integrantes-grupo-familiar{display:flex;flex-direction:column;grid-gap:16px;gap:16px}.asistencia-historial-grupo-familiar-modal .filtro-select-button{width:80%}.asistencia-historial-grupo-familiar-modal .filtro-select-button-mobile{width:80%;margin-bottom:16px}.asistencia-historial-grupo-familiar-modal .separador_finalizadas{margin-top:0px}.asistencia-historial-grupo-familiar-modal .filtro-finalizadas__container .boton-pregunta-container{padding:0px !important}
.asistencia-historial-skeleton .row__margin--top{margin-top:20px}
.atenciones__finalizadas__skeleton .atencion{margin-top:var(--size-10);margin-bottom:var(--size-10)}
.asistencia-historial-atenciones .input-padding{padding:15px 0}.asistencia-historial-atenciones .grid{padding:var(--size-24);padding-top:var(--size-0)}.asistencia-historial-atenciones .grid .year{padding:18px 0px 8px 0px}.asistencia-historial-atenciones .grid .tabs__padding{padding-top:var(--size-18);padding-bottom:var(--size-24)}.asistencia-historial-atenciones .grid .card-atenciones-historial{padding-top:var(--size-10);padding-bottom:var(--size-10)}.asistencia-historial-atenciones .grid .lista-atenciones-vacio{padding-top:var(--size-20)}.asistencia-historial-atenciones .grid .sin-atenciones__row{padding-top:24px}.asistencia-historial-atenciones .button--ripple{--ripple-color: transparent;margin-bottom:0px}.asistencia-historial-atenciones .img-asistencias-registradas{text-align:center;padding-top:var(--size-24)}.asistencia-historial-atenciones .finalizadas__subtitulo{padding-top:var(--size-24)}
.datos-atencion-accordion__row{margin-bottom:var(--size-16)}
.datos-contacto-accordion__row{margin-bottom:var(--size-16)}
.datos-socio-accordion__row{margin-bottom:var(--size-16)}
.datos-ubicacion-accordion__row{margin-bottom:var(--size-16)}
.detalle__caso__skeleton .dc__skeleton__container{padding:64px 24px 0 24px}.detalle__caso__skeleton .detallecaso__skeleton__row{display:flex;flex-direction:row;align-items:center;justify-content:space-between;padding-bottom:20px}.detalle__caso__skeleton .dc__center{display:flex;justify-content:center}.detalle__caso__skeleton .dc__right{display:flex;justify-content:flex-end}.detalle__caso__skeleton .title__col{padding-top:8px;padding-bottom:20px}.detalle__caso__skeleton .radio-item{margin-top:8px}
.asistencia-medica-detalle-caso .grid{padding:24px 24px 15vh 24px}.asistencia-medica-detalle-caso .datos-atencion__row{margin-top:var(--size-40)}.asistencia-medica-detalle-caso .datos-ubicacion__row{margin-bottom:var(--size-40)}
.datos-ubicacion-prestador__row{margin-bottom:var(--size-16)}
.telefonos__page .contactanos-row{margin-bottom:16px}
.asistencia-medica-seguimiento-vacio .seguimiento__grid{padding:var(--size-24)}.asistencia-medica-seguimiento-vacio .subtitulo{padding-bottom:var(--size-20)}
.direccion__seleccionada__component .row--margin{margin-bottom:24px}
.datos-ysocial .contacto__row--margin-top{margin-top:40px}.datos-ysocial .contenido-inputs{padding-top:16px}.datos-ysocial .continuar__ysocial__button{margin-top:20px;margin-bottom:45px}.datos-ysocial .row-no-datos{margin-bottom:10px}.datos-ysocial .domicilios_card{border:2px solid #f1f2fa;width:100%;border-radius:16px;padding:10px 24px;overflow:hidden;max-height:40%}
.row-form-continuar{margin-top:20px}
.ysocial-direcciones .button--ripple{--ripple-color: transparent;margin-bottom:0px}.ysocial-direcciones .georeferencia__col{padding-right:24px;padding-left:24px;padding-top:24px}.ysocial-direcciones .direcciones__col--padding{padding-right:24px;margin-bottom:25vh}.ysocial-direcciones .sin-domicilio{padding:24px 24px 16px 24px}.direcciones_padding{padding:0 24px 24px}.direcciones_container{display:flex;justify-content:space-between;flex-direction:column}
.datos-ubicacion{box-sizing:border-box;width:100vw;display:flex;flex-direction:column;align-items:flex-start;padding:16px;background:#fff;border:2px solid #e7e9f7;border-radius:16px;margin-top:8px}.datos-ubicacion .subtitulo--padding{padding:0px}.col__radio-button{align-items:center;display:flex;justify-content:center}.option{background:#df3045;border-radius:16px}.option .icon{font-size:var(--size-24)}.option .label--underline{text-decoration:underline;text-transform:capitalize}
ion-grid.content__grid{padding:24px}ion-col.grid__col{padding-top:24px}.asistencia-medica ion-header.modal__header{padding:54px 24px 24px;position:fixed;background:var(--white);z-index:99999;border-radius:24px 24px 0 0}.asistencia-medica ion-header.modal__header h2{padding:0}ion-grid.modal__grid{padding:115px 24px 0 24px;--inner-padding-end: 0px}.ysocial-background-page{background:linear-gradient(180deg, rgb(18, 38, 170) 50%, rgb(56, 76, 207) 100%);width:100%}.ysocial-padding{padding-bottom:0px}@media screen and (max-width: 750px){.ysocial-padding{padding-bottom:50px}}.ysocial_content-container{width:35rem;min-height:80vh;margin:auto}@media screen and (max-width: 750px){.ysocial_content-container{width:100%}}.feedback .feedback--container .botones__area{padding-bottom:4rem}
ion-grid.content__grid{padding:24px}ion-col.grid__col{padding-top:24px}.asistencia-medica ion-header.modal__header{padding:54px 24px 24px;position:fixed;background:var(--white);z-index:99999;border-radius:24px 24px 0 0}.asistencia-medica ion-header.modal__header h2{padding:0}ion-grid.modal__grid{padding:115px 24px 0 24px;--inner-padding-end: 0px}.ysocial-background-page{background:linear-gradient(180deg, rgb(18, 38, 170) 50%, rgb(56, 76, 207) 100%);width:100%}.ysocial-padding{padding-bottom:0px}@media screen and (max-width: 750px){.ysocial-padding{padding-bottom:50px}}.ysocial_content-container{width:35rem;min-height:80vh;margin:auto}@media screen and (max-width: 750px){.ysocial_content-container{width:100%}}.feedback .feedback--container .botones__area{padding-bottom:4rem}
.asistencia-resumen-ysocial .solicitar-asistencia-ysocial-card{margin-bottom:0px}.asistencia-resumen-ysocial .contacto__ysocial_row--margin-top{margin-top:40px}.asistencia-resumen-ysocial .contenido-ysocial-inputs{padding-top:16px}.asistencia-resumen-ysocial .continuar-ysocial__button{margin-top:20px;margin-bottom:45px}@media screen and (max-width: 750px){.asistencia-resumen-ysocial{width:100%}.asistencia-resumen-ysocial .sticky-button-container{position:-webkit-sticky;position:sticky;display:flex;align-items:center;justify-content:center;width:100%;padding:0;bottom:0}}

ion-grid.content__grid{padding:24px}ion-col.grid__col{padding-top:24px}.asistencia-medica ion-header.modal__header{padding:54px 24px 24px;position:fixed;background:var(--white);z-index:99999;border-radius:24px 24px 0 0}.asistencia-medica ion-header.modal__header h2{padding:0}ion-grid.modal__grid{padding:115px 24px 0 24px;--inner-padding-end: 0px}.ysocial-background-page{background:linear-gradient(180deg, rgb(18, 38, 170) 50%, rgb(56, 76, 207) 100%);width:100%}.ysocial-padding{padding-bottom:0px}@media screen and (max-width: 750px){.ysocial-padding{padding-bottom:50px}}.ysocial_content-container{width:35rem;min-height:80vh;margin:auto}@media screen and (max-width: 750px){.ysocial_content-container{width:100%}}.feedback .feedback--container .botones__area{padding-bottom:4rem}
ion-grid.content__grid{padding:24px}ion-col.grid__col{padding-top:24px}.asistencia-medica ion-header.modal__header{padding:54px 24px 24px;position:fixed;background:var(--white);z-index:99999;border-radius:24px 24px 0 0}.asistencia-medica ion-header.modal__header h2{padding:0}ion-grid.modal__grid{padding:115px 24px 0 24px;--inner-padding-end: 0px}.ysocial-background-page{background:linear-gradient(180deg, rgb(18, 38, 170) 50%, rgb(56, 76, 207) 100%);width:100%}.ysocial-padding{padding-bottom:0px}@media screen and (max-width: 750px){.ysocial-padding{padding-bottom:50px}}.ysocial_content-container{width:35rem;min-height:80vh;margin:auto}@media screen and (max-width: 750px){.ysocial_content-container{width:100%}}.asistencia-formulario__header{padding:54px 24px 24px;position:fixed;background:var(--white);z-index:99999;border-radius:24px 24px 0 0;width:100%}.asistencia-formulario__header h2{padding:0}.asistencia-formulario .col__align{align-self:center}.asistencia-formulario .col--padding-top{padding-top:16px}.asistencia-formulario .col--padding-right{padding-right:3.5px}.asistencia-formulario .col--padding-left{padding-left:3.5px}.asistencia-formulario .button--margin{margin-top:24px}.asistencia-formulario-mobile .guardar-cambios{margin-bottom:16px}
.loading-screen-ysocial{display:flex;justify-content:center;align-items:center;height:100vh;background-color:#fff}.loading-animation-ysocial{width:30rem;height:30rem;animation:pulse 2s infinite}@keyframes pulse{0%{transform:scale(0.8)}70%{transform:scale(1)}100%{transform:scale(0.8)}}
.telefonos__page__ysocial .contactanos-row{margin-bottom:16px}
.loading-screen{display:flex;justify-content:center;align-items:center;height:100vh;background-color:#1226aa}.loading-animation{width:30rem;height:30rem;animation:pulse 2s infinite}@keyframes pulse{0%{transform:scale(0.8)}70%{transform:scale(1)}100%{transform:scale(0.8)}}
ion-grid.content__grid{padding:24px}ion-col.grid__col{padding-top:24px}.asistencia-medica ion-header.modal__header{padding:54px 24px 24px;position:fixed;background:var(--white);z-index:99999;border-radius:24px 24px 0 0}.asistencia-medica ion-header.modal__header h2{padding:0}ion-grid.modal__grid{padding:115px 24px 0 24px;--inner-padding-end: 0px}.ysocial-background-page{background:linear-gradient(180deg, rgb(18, 38, 170) 50%, rgb(56, 76, 207) 100%);width:100%}.ysocial-padding{padding-bottom:0px}@media screen and (max-width: 750px){.ysocial-padding{padding-bottom:50px}}.ysocial_content-container{width:35rem;min-height:80vh;margin:auto}@media screen and (max-width: 750px){.ysocial_content-container{width:100%}}
