@import "../../../../themes/variables.scss";

.asistencia-terminos-condiciones {
    .text__label {
        padding-bottom: 24px;
        font-family: var(--font-family-secondary);
        font-style: normal;
        font-weight: 600;
        font-size: var(--size-12);
        line-height: 16px;
        
        .text__label--color {
            color: var(--primary-black);
        }
    }

    .footer {
        background-color: rgba(255,255,255,0.5);
        padding: 23px 24px 59px 24px;

        .footer_button {
            margin: 0px;
        }
    }
}