@mixin respond($breakpoint) {
	// $breakpoint is simply a variable that can have several values

	@if $breakpoint==tablet {
		// here `laptop` is the value of $breakpoint
		// when call laptop, we mean the following piece of code

		@media only screen and (max-width: 600px) {
			@content;
		}
	}

	@if $breakpoint==mobileSM {
		@media only screen and (max-width: 374px) and (max-height: 710px) {
			@content;
		}
	}
	@if $breakpoint==mobileXL {
		@media only screen and (min-width: 375px) and (min-height:710px) {
			@content;
		}
	}
}

@mixin for-screen-very-small {
	@media only screen and (max-height: 400px){
		@content;
	}
}

@mixin for-phone-only-small-high {
	@media only screen and (max-height: 710px) {
		@content;
	}
}

@mixin for-phone-only-tall-high{
	@media only screen and (min-height: 710px){
		@content;
	}
}
