@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;700;800;900&family=Nunito:wght@300;400;700;800;900&display=swap");

ion-grid.content__grid {
	padding: 24px;
}

ion-col.grid__col {
	padding-top: 24px;
}

.asistencia-medica {
	ion-header.modal__header {
		padding: 54px 24px 24px;
		position: fixed;
		background: var(--white);
		z-index: 99999;
		border-radius: 24px 24px 0 0;

		h2 {
			padding: 0;
		}
	}
}

ion-grid.modal__grid {
	padding: 115px 24px 0 24px;
	--inner-padding-end: 0px;
}

//Estilos asociados a WebYsocial
.ysocial-background-page {
	background: linear-gradient(180deg, rgb(18, 38, 170) 50%, rgb(56, 76, 207) 100%);
	width: 100%;
}

.ysocial-padding {
	padding-bottom: 0px;

	@media screen and (max-width: 750px) {
		padding-bottom: 50px;
	}
}

.ysocial_content-container {
	width: 35rem;
	min-height: 80vh;
	margin: auto;

	@media screen and (max-width: 750px) {
		width: 100%;
	}
}