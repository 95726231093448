.web_layout-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
}
.asistencia_content-container {
    width: 35rem;
    min-height: 80vh;
    margin: auto;
    @media screen and (max-width: 750px) {
        width: 100%;
    }
}
