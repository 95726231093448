.asistencia-preguntas {

    .seleccionar-socio__title{
        padding-bottom: 0px;
        margin-bottom: 8px;
    }
    .preguntas__row_button_continuar {
        padding-top: 20px;
    }
    
    .preguntas__button_preguntas {
        margin: 0px;
    }
}

