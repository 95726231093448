@import "../../../themes/variables.scss";

.asistencia-error_page {
    background: linear-gradient(180deg, rgb(18, 38, 170) 50%, rgb(56, 76, 207) 100%);
    width: 100%;
}

.asistencia-web-mobile-padding {
    padding-bottom: 0px;
    @media screen and (max-width: 750px) {
        padding-bottom: 50px;
    }
}