.asistencia-medica-servicio-en-curso {
    .titulo-servicio-en-curso {
        padding: 0 0 var(--size-16);
    }

    .subtitulo-servicio-en-curso {
        padding: 0 0 var(--size-24);
    }

    .boton-volver {
        padding-left: 24px;
        padding-right: 3.5px;
    }
    
    .boton-contactanos {
        padding-right: 24px;
        padding-left: 3.5px;
    }
}