.asistencia-medica-deshabilitar-asistencia {
    .deshabilitar__grid {
        padding: var(--size-24);
    }
    .subtitulo {
        padding-bottom: var(--size-20);
    }

}

.asistencia-medica-deshabilitar-asistencia-mobile {
    .row {
        margin-top: var(--size-16);
    }
}