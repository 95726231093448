.asistencia-medica-skeleton {
	.col--display {
		display: flex;
		justify-content: center;
	}

    .title{
        padding-bottom: 8px;
    }
    .button-link{
        margin-top: 8px;
    }
	.item__skeleton--50 {
		width: 50vw;
	}

	.item__row {
		width: 100vw;
	}

	.item__grid {
		margin-bottom: 20px;
	}

	.solicitar-asistencia__card {
		display: flex;
		flex-direction: column;
		border-radius: 16px;
		box-shadow: none;
		--background: #E7E9F7;
	
		.card__title {
			margin-bottom: 5px;
		}
	
		.card__button {
			margin: 16px 0 0 0;
		}
	}

	.footer-terminos-condiciones {
		margin-bottom: 8px;
		padding: 0 0 100px 0;
	}
}
