.asistencia-seleccionar-domicilio { 
  .sd__title {
    padding-bottom: 5px;
  }

  .domicilios_card {
    border: 2px solid #F1F2FA;
    width: 100%;
    border-radius: 16px;
    padding: 10px 24px;
    overflow: hidden;
    max-height: 40%;
  }

  .domicilio__info {
    display: flex;
    flex-direction: column;
    .info__item {
      font-size: var(--size-5);
    }
    .info__title {
      font-weight: bold;
    }
    .subtitulo--padding {
      padding: 0px;
    }
  }

  .form__link {
    padding: 15px 0;
    justify-content: flex-end;
  }
  
  .seleccionar-domicilio__button {
    margin-bottom: 45px;
  }

  .seleccionar-domicilio-mobile__button {
    margin-bottom: 45px;
  }
}