.asistencia-historial-atenciones {
    .input-padding{
        padding: 15px 0;
    }
    .grid {
        padding: var(--size-24);
        padding-top: var(--size-0);
        .year {
            padding: 18px 0px 8px 0px;
        }
        .tabs__padding {
            padding-top: var(--size-18);
            padding-bottom: var(--size-24);
        }
        .card-atenciones-historial {
            padding-top: var(--size-10);
            padding-bottom: var(--size-10);
        }
        .lista-atenciones-vacio {
            padding-top: var(--size-20);
        }
        .sin-atenciones__row {
            padding-top: 24px;
        }
    }

    
    .button--ripple {
        --ripple-color: transparent;
        margin-bottom: 0px;
    }

    .img-asistencias-registradas {
        text-align: center;
        padding-top: var(--size-24);
    }

    .finalizadas__subtitulo {
        padding-top: var(--size-24);
    }
}