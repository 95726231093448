.asistencia__pedidoServicio {

    .footer__button {
        padding-right: 24px;
        padding-left: 24px;
    }

    .pedidoServicio__button_solicitar {
        margin: 0px;
    }

    .pedido-servicio--row {
        padding: 24px 24px 0;
    }

    .cancelar__button {
        padding-bottom: 14px;
    }
    .pedidoServicio__title {
        padding: 0px;
    }
}

.asistencia__pedidoServicio-mobile {
    .content__grid {
        padding: 0 0 var(--size-16) 0;
    }

    .pedidoServicio__title {
        padding: 0px;
    }
}